:root {
    --radius: 50px;
    --thickness: 10px;
    --time: 900ms
}

.outer, .inner {
    border-radius: 100%;
    width: 0;
    height: 0;
}
.outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: calc(var(--radius) + var(--thickness)) solid transparent;
    border-top-color: rgb(73, 73, 73);
    border-bottom-color: rgb(73, 73, 73);
    animation: rotate var(--time) linear infinite;
}

.inner {
    border: calc(var(--radius)) solid transparent;
    background-color: rgb(250, 250, 250);
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}